import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Breadcrumb from '../../components/breadcrumb'
import GenericPage from '../../components/genericPage'

import content from '../../json/2021/credits.json'

const CreditsPage = ({location}) => {
  return (
    <Layout year="2021" simpleHeader={true} url="/2021/credits-et-mentions-legales">
      <SEO 
        title="Crédits et mentions"
        description="Crédits et mentions légales de l'Observatoire - 2021"
        path="/2021/credits-et-mentions-legales"
        year="2021" />

      <Breadcrumb url="/2021/credits-et-mentions-legales" label="Crédits & mentions légales" type="credits" year="2021" /> 

      <GenericPage slug="credits" content={content} />
    </Layout>
  )
  }

export default CreditsPage

